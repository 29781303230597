<template>
  <div class="product">
    <banner></banner>
    <div class="site-content animate">
      <main class="site-main">
        <article class="hentry">
          <!-- 文章头部 -->
          <header class="entry-header">
            <!-- 标题输出 -->
            <h1 class="entry-title">企业产品</h1>
            <hr />
            <div class="breadcrumbs">
              <div id="crumbs">企业产品最后更新时间：2021年07月25日</div>
            </div>
          </header>
          <!-- 正文输出 -->
          <div class="entry-content">
            <h4 id="糕点主要产品">糕点主要产品</h4>
            <ul>
              <li>椒盐五仁酥</li>
              <li>精品赵州饼</li>
              <li>软皮红枣</li>
              <li>软皮蜜豆</li>
              <li>软皮五仁</li>
              <li>更多...</li>
            </ul>
            <hr />
            <h4 id="椒盐五仁酥">椒盐五仁酥</h4>
            <img
              class="productImg"
              src="https://s4.ax1x.com/2022/02/24/bi5bo6.png"
              alt=""
            />
            <p>产品特点：</p>
            <p>咸---香---酥---松</p>
            <p>不油，不硬，不甜</p>
            <p>从皮酥到馅</p>
            <hr />
            <h4 id="精品赵州饼">精品赵州饼</h4>
            <img
              class="productImg"
              src="https://s4.ax1x.com/2022/02/24/bi5LFK.png"
              alt=""
            />
            <p>产品特点：</p>
            <p>带点赵州老月饼，常回家看看</p>
            <p>烤前皮白如玉，烤后自然发红</p>
            <p>软香可口，酥甜不腻</p>
            <p>主打爆款</p>
            <hr />
            <h4 id="软皮红枣">软皮红枣</h4>
            <img
              class="productImg"
              src="https://s4.ax1x.com/2022/02/24/bi5OJO.png"
              alt=""
            />
            <p>产品特点：</p>
            <p>绵绵软软，微甜不腻</p>
            <p>第三代独家改良红枣配方，外面一颗蜜枣，馅料满满和红枣和芝麻</p>
            <hr />
            <h4 id="软皮蜜豆">软皮蜜豆</h4>
            <img
              class="productImg"
              src="https://s4.ax1x.com/2022/02/24/bi57e1.png"
              alt=""
            />
            <p>产品特点：</p>
            <p>颗颗红豆，粒粒绿豆</p>
            <p>软软绵绵，甜甜蜜蜜</p>
            <hr />
            <h4 id="软皮五仁">软皮五仁</h4>
            <img
              class="productImg"
              src="https://s4.ax1x.com/2022/02/24/bi5Hdx.png"
              alt=""
            />
            <p>产品特点：</p>
            <p>香甜可口，绵软不腻</p>
            <p>芝麻花生葡萄干，越简单越好吃</p>
            <hr />
            <h4 id="赵州饼礼盒">赵州饼礼盒</h4>
            <ul>
              <li>经济礼盒</li>
              <li>中端礼盒</li>
              <li>高端礼盒</li>
            </ul>
            <hr />
            <h4 id="经济礼盒">经济礼盒</h4>
            <img
              class="productImgs"
              src="https://s4.ax1x.com/2022/02/24/biLKv8.jpg"
              alt=""
            />
            <img
              class="productImgs"
              src="https://s4.ax1x.com/2022/02/24/biLQKS.png"
              alt=""
            />
            <img
              class="productImgs"
              src="https://s4.ax1x.com/2022/02/24/biLlDg.png"
              alt=""
            />
            <p>走亲访友必备</p>
            <hr />
            <h4 id="中端礼盒">中端礼盒</h4>
            <img
              class="productImgs"
              src="https://s4.ax1x.com/2022/02/24/biLy5R.png"
              alt=""
            />
            <img
              class="productImgs"
              src="https://s4.ax1x.com/2022/02/24/biLsa9.png"
              alt=""
            />
            <img
              class="productImgs"
              src="https://s4.ax1x.com/2022/02/24/biL0rF.jpg"
              alt=""
            />
            <p>孝顺长辈，求人办事，不失颜面</p>
            <hr />
            <h4 id="高端礼盒">高端礼盒</h4>
            <img
              class="productImgs"
              src="https://s4.ax1x.com/2022/02/24/biLTIA.jpg"
              alt=""
            />
            <img
              class="productImgs"
              src="https://s4.ax1x.com/2022/02/24/biLoad.jpg"
              alt=""
            />
            <img
              class="productImgs"
              src="https://s4.ax1x.com/2022/02/24/biLIVH.jpg"
              alt=""
            />
            <p>至臻礼盒，让您做什么事情都倍有面儿</p>
            <hr />
            <img class="productImgw" src="https://s4.ax1x.com/2022/02/24/biLHPI.jpg" alt="">
          </div>
        </article>
      </main>
    </div>
  </div>
</template>

<script>
import Banner from "@/components/banner";
export default {
  name: "product",
  data() {
    return {
      showDonate: false,
      comments: [],
      menus: [],
    };
  },
  components: {
    Banner,
  },
  methods: {},
  mounted() {},
  created() {},
};
</script>
<style scoped lang="less">
.site-content {
  position: relative;
  .site-main {
    padding: 80px 0 0 0;
  }
}
article.hentry {
  .entry-header {
    .entry-title {
      font-size: 23px;
      font-weight: 600;
      color: #737373;
      margin: 0.67em 0;

      &:before {
        margin-right: 6px;
        color: #d82e16;
        font-size: 20px;
        font-weight: 600;
      }
    }

    hr {
      height: 1px;
      border: 0;
      background: #efefef;
      margin: 15px 0;
    }

    .breadcrumbs {
      font-size: 14px;
      color: #d2d2d2;
      text-decoration: none;
      margin-bottom: 30px;
    }
  }
}
.productImg {
  width: 40%;
}
.productImgs {
  width: 30%;
  margin-right: 2%;
}
.productImgw{
  width: 100%;
  margin-bottom: 20px;
}
</style>
